import { createApp } from 'vue'
import App from './App.vue'
// import Header from "./common/Header.vue";
// import Footer from "./common/Footer.vue";
import Dialog from "@/components/Dialog.vue";

const app = createApp(App);

app.config.productionTip = false;

app.component('Dialog', Dialog);
// app.component('Header', Header);
// app.component('Footer', Footer);

app.mount('#app')
