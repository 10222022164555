<template>
  <div id="app" v-cloak>
    <index></index>
  </div>
</template>

<script>
import index from './page/index.vue'

export default {
  name: 'App',
  components: {
    index
  }
}
</script>


