<template>
    <div>
        <!-- 弹层阴影 -->
        <div class="dialog-gray-mark" :class="{'fade-enter-mark':isShowDialogMark, 'fade-leave-mark':!isShowDialogMark}" v-show="isShowDialogMark" @click="hideDialog()"></div>

        <div class="dialog-box" :class="{'toast-fade-enter':isShowDialog}" :style="{width: width}" v-show="isShowDialog">
            <a href="javascript:;" class="close" @click="hideDialog()"><i></i></a>

            <div class="dialog-header" rel="title_box" v-if="!$slots.title && show_top">
                <div class="left-tvf">
                    <div class="title">{{ title }}</div>
                </div>
            </div>
            <slot name="header"></slot>

            <slot name="body"></slot>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { default: '' },
        show_top: { default: true },
        width: {default: '520px'},
    },
    data() {
        return {
            isShowDialog: false,
            isShowDialogMark: false,
            dialogName: '',
        }
    },
    computed: {
        storageIcon() {
            return this.$store.state.storageIcon;
        }
    },
    methods: {
        hideDialog() {
            this.isShowDialogMark = false;
            this.isShowDialog = false;
            this.$emit('dgStatus', false);
        },
        showBox: function() {
            this.isShowDialogMark = true;
            this.isShowDialog = true;
            this.$emit('dgStatus', true);
        },
    },
}
</script>