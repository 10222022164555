<template>
    <div class="footer">
        <div class="inner">
            <div class="left-tvf">
                <div class="copyright">© 2024 MingCute Design. All rights reserved</div>
            </div>
            <div class="right-tvf">
                <div class="opt">
                    <a href="javascript:;" class="link" @click="handleAgreement"><span>License</span></a>
                    <a href="https://store.mingcute.com/affiliates" target="_blank" class="link">Affiliates</a>
                    <a href="javascript:;" class="link" @click="handleClick"><span>About Us</span></a>
                </div>
                <div class="opt">
                    <a href="https://twitter.com/MingCute_icon" target="_blank" class="link-icon"><i class="icon-x"></i></a>
                    <a href="https://www.figma.com/@mingcute" target="_blank"class="link-icon"><i class="icon-figma"></i></a>
                </div>
            </div>
        </div>

        <!-- 关于我们 -->
        <Dialog
            ref="aboutUsDg"
            title="About Us"
        >
            <template v-slot:body>
                <div class="mingcute-aboutus-dialog">
                    <ul>
                        <li>
                            <div class="pic">R</div>
                            <div class="name">Richard</div>
                            <div class="position">Designer</div>
                            <div class="email">9394lay@gmail.com</div>
                        </li>
                        <li>
                            <div class="pic">X</div>
                            <div class="name">Xin</div>
                            <div class="position">Developer</div>
                            <div class="email">2536354155@qq.com</div>
                        </li>
                    </ul>
                </div>
            </template>
            
        </Dialog>
        <!-- 协议 -->
        <Dialog
            ref="agreementDg"
            show_top=""
            title=""
            width="800px"
        >
            <template v-slot:body>
                <div class="agreement-dialog">
                    <div class="agreement-scroll">
                        <div class="title">License Agreement</div>
                        <div class="agreement-cont">
                            <div class="part">
                                <p>By purchasing, downloading, and using our digital products, you are consenting
    to the terms outlined in this license agreement. Learn what you can and can't 
    do with the files you'll get after the purchase.</p>
                                <p>The license provides users with a continuous, non-exclusive, global right to 
    use digital works ("Items").</p>
                                <p>You can use the purchased items to create unlimited end products for yourself or your clients, and these end products can be sold, licensed, or sublicensed.</p>
                            </div>
                            <div class="part">
                                <div class="part-title">An end product is one of the following</div>
                                <p>The End Product is a customized implementation of the Item.  For example,  the Item is a  pack of UI elements, the end product is your unique finished website 
    or application design.</p>
                            </div>
                            <div class="part">
                                <div class="part-title">Permitted Uses:</div>
                                <ul>
                                    <li>
                                        <i class="icon-right"></i>
                                        <span>Develop an end product for clients</span>
                                    </li>
                                    <li>
                                        <i class="icon-right"></i>
                                        <span>Create an end product for personal or commercial use</span>
                                    </li>
                                    <li>
                                        <i class="icon-right"></i>
                                        <span>Combine the Item with other works and create derivative works. The resulting works are subject to the terms of this license.</span>
                                    </li>
                                    <li>
                                        <i class="icon-right"></i>
                                        <span>Use in multiple end products without restrictions</span>
                                    </li>
                                    <li>
                                        <i class="icon-right"></i>
                                        <span>Only publish the Item in a team library or share files with participants within an organization when you have purchased a Team or Enterprise license. The team license allows you to grant editing permissions to other team members of the company.</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="part">
                                <div class="part-title">Forbidden Uses:</div>
                                <ul>
                                    <li>
                                        <i class="icon-cross"></i>
                                        <span>Publicly share the item or its parts on the internet</span>
                                    </li>
                                    <li>
                                        <i class="icon-cross"></i>
                                        <span>Share links to the Item or distribute/embed it using Figma or other software on the internet</span>
                                    </li>
                                    <li>
                                        <i class="icon-cross"></i>
                                        <span>Place any of Item design files into the End Product as it is</span>
                                    </li>
                                    <li>
                                        <i class="icon-cross"></i>
                                        <span>Redistribute the Item as stock, in tools or templates, or redistribute through source files</span>
                                    </li>
                                    <li>
                                        <i class="icon-cross"></i>
                                        <span>Redistribute or offer the Item as is or with superficial modifications</span>
                                    </li>
                                    <li>
                                        <i class="icon-cross"></i>
                                        <span>Exceeding limits: Do not use more than 500 icons (4,500 across 9 styles) in a commercial End Product like a website or app. Do not use more than 250 icons (2,250 across 9 styles) in digital or physical End Products for resale, such as UI kits, themes, website t-shirts, books, etc.</span>
                                    </li>
                                    <li>
                                        <i class="icon-cross"></i>
                                        <span>If you have purchased a single license, you cannot publish the Item in a team library or grant editing permissions to others. Purchase a Team or Enterprise license if multiple individuals will be using the file.</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="part">
                                <div class="part-title">Refund Policy</div>
                                <p>We provide a free preview version that allows you to browse the complete icon library, including some of the source icons and features.However, as the icon system required substantial effort to create, we generally do not offer money-back guarantees unless there is a valid reason. If you have any concerns about your purchase, please contact us at <a href="mailto:9394lay@gmail.com" target="_blank">9394lay@gmail.com</a> within 10 days of your purchase, and we will do our best to assist you. Our goal is to provide an excellent customer experience and ensure your satisfaction with MGC.</p>
                            </div>
                            <div class="part">
                                <div class="part-title">Enforcement</div>
                                <p>If the licensor has reason to suspect that the customer has violated this agreement, the licensor may terminate the agreement and restrict the customer's ability to download the assets. Upon termination of the agreement, the original and all copies of the Asset, including backup copies, must be destroyed. </p>
                                <p>Notice of termination or access restriction will be sent to the email address provided at purchase. These measures do not limit the licensor's ability to enforce this agreement through other means (such as legal action).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Footer',
    methods: {
        handleClick() {
            this.$refs.aboutUsDg.showBox();
        },
        handleAgreement() {
            this.$refs.agreementDg.showBox();
        },
    }
});

</script>